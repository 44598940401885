<template>
    <div>
        <loading-card v-show="fetchingRows || fetchingTableau || saving">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else>Chargement des affaires ...</template>
        </loading-card>

        <div v-show="!(fetchingRows || fetchingTableau || saving)" class="gestion">
            <b-form @keydown.enter.prevent>
                <validation-observer ref="simpleRules">
                    <b-row>
                        <b-col lg="12">
                            <b-row>
                                <h4 class="card-title float-left ml-2 mt-1">Tableau de Gestion du :</h4>
                                <b-col lg="4" sm="6">
                                    <b-form-group label-for="datePointGestion">
                                        <validation-provider #default="{ errors }" name="Date" rules="required">
                                            <b-form-datepicker
                                                locale="fr" 
                                                :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                                v-model="tableauGestion.datePointGestion"
                                                id="datePointGestion"
                                                placeholder="Date"
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="!creating"
                                            />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </validation-observer>
            </b-form>

            <b-row v-if="!creating">
                <b-col lg="12">
                    <b-card>
                        <!-- table -->
                        <vue-good-table
                            
                            :columns="columns"
                            :rows="affaires"
                            :initialSortBy="initialSortBy"
                            max-height="700px"
                            :row-style-class="rowClassCallback"
                            @on-cell-click="onCellClick"
                            :theme="isDark ? 'nocturnal' : ''"
                            styleClass="vgt-table condensed tableau-gestion"
                        >
                            <template #emptystate>
                                <div class="text-center"> Aucune Affaire n'a été trouvée </div>
                            </template>

                            <template slot="table-row" slot-scope="props">
                                <template v-if="props.column.field === 'croissance'">
                                    <feather-icon
                                        v-if="props.row.croissance != 0"
                                        :icon="getCroissanceIcon(props.row[props.column.field])"
                                        size="15"
                                        :class="getCroissanceClass(props.row[props.column.field])"
                                    />
                                    <span v-else>-</span>
                                </template>

                                <template v-else-if="props.column.field == 'action' && showAction(props.row)">
                                    <b-button
                                        size="sm"
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        class="btn-icon"
                                        @click="$router.push({path: `/pointGestions/${props.row.lastPointGestionChargeAffaire.id}/edit`})"
                                    >
                                        <feather-icon icon="Edit2Icon" size="1x"/>
                                    </b-button>
                                </template>

                                <template v-else-if="applyFormatCallbackTo.includes(props.column.field)">
                                    {{getFormattedCellValue(props)}}
                                </template>
                            </template>
                        </vue-good-table>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="12">
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="$router.go(-1)" class="mr-1">
                        <feather-icon icon="ListIcon" class="mr-50"/>
                        <span class="align-middle">Revenir à la liste</span>
                    </b-button>

                    <b-button variant="gradient-primary" type="submit" @click.prevent="downloadFile" v-if="tableauGestion.valid">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Exporter</span>
                    </b-button>

                    <b-button variant="gradient-success" type="submit" @click.prevent="validationForm(false)" class="mr-1" v-if="creating">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">{{creating ? "Ajouter" : "Enregistrer"}}</span>
                    </b-button>

                    <b-button variant="gradient-success" class="mr-1" type="submit" @click.prevent="validationForm(true)" v-if="!creating && !tableauGestion.valid">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Valider</span>
                    </b-button>
                </b-col>
            </b-row>

            <template v-if="extraRowsMounted">
                <MountingPortal 
                mountTo="#extra-rows-top"
                targetTag="thead"
                >
                    <tr class="border-white extra">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                        <th class="grey-bg">PV Budget</th>
                        <th class="grey-bg">MB Budget</th>
                        <th class="grey-bg">MB %</th>
                        <th class="grey-bg">NBR</th>

                        <th class="blue-bg">PV PGM</th>
                        <th class="blue-bg">PR PGM</th>
                        <th class="blue-bg">MB PGM</th>
                        <th class="blue-bg">MB %</th>

                        <th class="white-bg border-bottom-white"></th>
                        <th colspan="14" class="white-bg"></th>
                        <th class="white-bg border-bottom-white"></th>
                    </tr>
                    <tr class="extra" v-for="type in ['E', 'S']" :key="`rowtot_${type}`">
                        <td class="border-white"></td>
                        <td class="border-white"></td>
                        <td class="border-white"></td>
                        <td class="border-white"></td>
                        <td class="border-white"></td>
                        <th class="border-white grey-bg text-center">{{type == 'E' ? 'ENCOURS' : 'SORTIE'}}</th>

                        <td>{{formatNumber(getFieldSum("totalCommande", type))}}</td>
                        <td>{{formatNumber(getFieldSum("margeCommande", type))}}</td>
                        <td>{{formatNumber(getPercentFieldSum("totalCommande", "margeCommande", type), '%')}}</td>
                        <td>{{countAffaires(type)}}</td>

                        <td>{{formatNumber(getFieldSum("totalVentePG", type))}}</td>
                        <td>{{formatNumber(getFieldSum("totalAchatPG", type))}}</td>
                        <td>{{formatNumber(getFieldSum("margePG", type))}}</td>
                        <td>{{formatNumber(getPercentFieldSum("totalVentePG", "margePG", type), "%")}}</td>
                        <td class="border-bottom-white"></td>
                        
                        <td >{{formatNumber(getFieldSum("activite", type))}}</td>
                        <td>{{formatNumber(getFieldSum("sousFact", type))}}</td>
                        <td>{{formatNumber(getFieldSum("factCumul", type))}}</td>
                        <td>{{formatNumber(getFieldSum("depenseCumul", type))}}</td>
                        <td>{{formatNumber(getFieldSum("mbCumul", type))}}</td>
                        <td>{{formatNumber(getPercentFieldSum("factCumul", "mbCumul", type), "%")}}</td>
                        <td>-</td>
                        <td>{{formatNumber(getFieldSum("engage", type))}}</td>

                        <td>{{formatNumber(getFieldSum("sitMeriteeN", type))}}</td>
                        <td>{{formatNumber(getFieldSum("enCoursN", type))}}</td>
                        <td>{{formatNumber(getFieldSum("factN", type))}}</td>
                        <td>{{formatNumber(getFieldSum("depenseN", type))}}</td>
                        <td>{{formatNumber(getFieldSum("MBN", type))}}</td>
                        <!-- TODO VERIFIER SI PREND LES BONNE VARIABLES EN COMPTE -->
                        <td>{{formatNumber(getPercentFieldSum("sitMeriteeN", "MBN", type), "%")}}</td>
                        <td class="border-white"></td>
                    </tr>
                </MountingPortal>
            </template>
        </div>
    </div>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'
    import LoadingCard from "@/views/GDLoadingCard"


    import { formatNumber, itemMatch } from "@/services/utils"
    import { tableauGestionColumns, lastPointGestionDirectionCells, applyFormatCallbackTo } from "@/views/setups/tableau_gestion_columns"
    import useAppConfig from '@core/app-config/useAppConfig'
    import {callUrl, downloadFile} from "@/api/api"
    import { computed, ref } from '@vue/composition-api'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import moment from "moment"
    import tableauGestionApi from "@/api/tableauGestionApi"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'


    export default {
        components: {
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            VueGoodTable,
            ToastificationContent
        },

        data() {
            return {
                saving: false,
                fetchingTableau: false,
                fetchingRows: false,
                tableauGestion: {datePointGestion: null, valid: false},
                extraRowsMounted: false,
                affaires: [],
                initialSortBy: [{field: 'nom', type: 'asc'}],
                pageLength: 25,
                columns: [
                    ...tableauGestionColumns,
                    {
                        label: '',
                        field: 'action',
                        width: '50px',
                        sortable: false,
                        tdClass: "text-center"
                    },
                ],
            }
        },

        beforeMount() {
            if(!this.creating)
                this.refreshTableauGestion();
            this.columns = this.columns.map(c => {
                if(lastPointGestionDirectionCells.includes(c.field)) {
                    // on cumule des classes fixes pour les colonnes, plus le résultat de notre callback dynamique pointerDir
                    let tdClass = (row) => this.pointerDirCallback(row) + " " + (c.hasOwnProperty('tdClass') ? c.tdClass : '')
                    return {...c, tdClass}
                }
                return c;
            })
        },

        setup(props, {root}) {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            let creatingBase = !root.$route.params.hasOwnProperty("tableauGestionId");
            const creating = ref(creatingBase)

            return { skin, isDark, formatNumber, applyFormatCallbackTo, creating, required}
        },

        computed: {
            dateExport() {return moment(this.tableauGestion.datePointGestion, 'YYYY-MM-DD').format("DD_MM_YYYY");},
            datePoint() {return moment(this.tableauGestion.datePointGestion, 'YYYY-MM-DD').format("DD/MM/YYYY");}
        },

        methods: {
            async refreshTableauGestion() {
                this.fetchingTableau = true;
                this.tableauGestion = await tableauGestionApi.getTableauGestion(this.$route.params.tableauGestionId);
                this.fetchingTableau = false;
                await this.refreshAffaires();
            },

            showAction(row) {
                if(this.tableauGestion.valid)
                    return false;
                return row.lastPointGestionChargeAffaire != null && row.lastPointGestionDirection?.id != row.lastPointGestionChargeAffaire?.id
            },

            async refreshAffaires() {
                this.fetchingRows = true;
                if(this.tableauGestion.valid) {
                    let res =  await callUrl("GET", "api/tableau_gestion_lignes", {tableauGestion: this.tableauGestion.id})
                    this.affaires = res['hydra:member'];
                }
                else
                    this.affaires = await callUrl("GET", `api/tableau/gestion/${this.tableauGestion.id}`);
                this.fetchingRows = false;

                this.$nextTick(this.upgradeTable)
            },

            upgradeTable() {
                let template = document.createElement('tr');
                template.innerHTML = "<th class='large-border-bottom'></th>".repeat(6) +
                "<th colspan='4' class='text-center large-border-bottom'>ENTREE COMMANDE</th>" +
                `<th colspan='5' class='text-center large-border-bottom'>POINT DE GESTION M : ${this.datePoint}</th>` +
                `<th colspan='8' class='text-center large-border-bottom'>COMPTABILISE CUMULE AU STADE : ${this.datePoint}</th>` +
                `<th colspan='6' class='text-center large-border-bottom'>COMPTABILISE EXERCICE AU STADE</th>` + 
                `<th class='large-border-bottom bg-white'></th>`;

                let target = this.$el.querySelector("table tr");
                target.parentNode.insertBefore(template, target);

                // les lignes extras avec données "somme"
                let template2 = document.createElement('tbody');
                template2.setAttribute("id", "extra-rows-top");
                let target2 = this.$el.querySelector("table thead");
                target2.parentNode.insertBefore(template2, target2);
                this.extraRowsMounted = true;
            },

            getCroissanceIcon(croissance) {
                return croissance == 1 ? "ArrowRightIcon" : (croissance == 2 ? 'ArrowUpIcon' : 'ArrowDownIcon')
            },

            getCroissanceClass(croissance) {
                return croissance == 1 ? "text-warning" : (croissance == 2 ? 'text-success' : 'text-danger')
            },

            onCellClick({row, column}) {
                if(lastPointGestionDirectionCells.includes(column.field) && row.lastPointGestionDirection != null)
                    this.$router.push({path: `/pointGestions/${row.lastPointGestionDirection.id}/edit` })
                else if(column.field == "affaire.titre" || column.field == "affaire.num")
                    this.$router.push({path: `/affaires/${row.affaire.id}/edit` })
            },

            pointerDirCallback({lastPointGestionDirection}) {
                return lastPointGestionDirection != null ? "pointer" : "not-allowed";
            },

            rowClassCallback(row) {
                if(row.sousFact < 0)
                    return "red-row";
            },

            getFormattedCellValue({column, row}) {
                let value = column.hasOwnProperty("valueCallback") ? column.valueCallback(row) : row[column.field];
                let isPercent = column.field.includes("%");
                return isPercent ? formatNumber(value, '%') : formatNumber(value)
            },

            getFieldSum(field, etat) {
                // includes car reçoit "S" ou "E". pour "S" => match "S" et "ST" ...
                let affaires = this.affaires.filter(a => a.etat.includes(etat))
                return affaires.reduce((accumulator, affaire) => accumulator + affaire[field], 0)
            },

            getPercentFieldSum(field1, field2, etat) {
                let value1 = this.getFieldSum(field1, etat);
                let value2 = this.getFieldSum(field2, etat);
                return value1 == 0 ? 0 : (value2 * 100) / value1;
            },

            countAffaires(etat) {
                return this.affaires.filter(a => a.etat.includes(etat)).length
            },

            async downloadFile() {
                let res = await downloadFile(`api/tableau/gestion/export/${this.tableauGestion.id}`, `tableau_gestion_${this.dateExport}.xlsx`)
                if(res == -1)
                    this.showToast("danger", "Une erreur s'est produite lors de la génération de l'export", "AlertCircleIcon")
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            async validationForm(validate = false) {
                this.$nextTick(async () => {
                    let res = await this.$refs.simpleRules.validate()
                                    
                    if(res) {
                        if(validate) {
                            let {isConfirmed} = await this.$swal({
                                title: 'Valider Tableau de Gestion',
                                text: `Etes-vous sûr de vouloir valider le tableau de gestion ? Les valeurs des lignes seront enregistrées et il ne sera plus possible de les modifier.`,
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Valider',
                                cancelButtonText: "Annuler",
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                    cancelButton: 'btn btn-outline-danger ml-1',
                                },
                                buttonsStyling: false,
                            })
                            
                            if(!isConfirmed)
                                return;
                        }
                        this.save(validate);
                    }

                    else {
                        this.showToast("danger", "Enregistrement impossible, veuillez vérifier les champs du formulaire", "AlertCircleIcon")
                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".is-invalid:first-of-type");
                            if(el.id == "titre")
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            else  {
                                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            }
                            return;
                        });
                    }
                })
            },

            async save(validate) {
                this.tableauGestion.valid = validate;
                this.saving = true;

                if(this.creating) {
                    this.tableauGestion = await tableauGestionApi.addTableauGestion(this.tableauGestion);
                    this.$router.replace(`/tableau_gestions/${this.tableauGestion.id}/edit`)
                    this.creating = false;
                    await this.refreshAffaires();
                }

                if(validate) {
                    this.affaires = await callUrl("GET", `api/tableau/gestion/validate/${this.tableauGestion.id}`)
                }

                this.saving = false;
            }
        }
    }
</script>

<style lang="scss">
    // beaucoup de styles (sticky, couleur de fonds ...) des th et td de VGT
    // auraient pu être ajoutés via tdClass et thClass (voir tableau_gestion_column)

    // idem, via des classes sur les cellules rajoutées via portal
    // pour la ligne de titre rajoutée en dur dans le mounted, css
    // même s'il avait possible de la rajouter elle aussi via portal
    @import "@/assets/scss/variables/_variables.scss";
    @import '../../assets/vuexy/src/assets/scss/variables/_variables.scss';
 
    $colspos: 0px, 50px, 250px, 285px, 350px, 385px;
    $yellowish: #f8c538;
    $blueish: #6a98d3;
    $greyish: #808080;
    $headercolor: #606266;
    $redish: #c12c18;
    $baseborder: #dcdfe6;

    .grey-bg {
        background: $greyish !important;
        color: white !important;
    }
    
    //Couleur colonne activite et sous fact
    .light-grey-bg {
        //background: #d0cecf !important;
        background: $greyish !important;
    }

    .blue-bg {
        background: $blueish !important;
        color: white !important;
    }

    .white-bg {
        background: white !important;
    }

    // colonnes sticky
    @for $i from 1 through length($colspos) {
        $pos: nth($colspos, $i);
        .gestion td:nth-child(#{$i}), .gestion tr:nth-child(2) th:nth-child(#{$i}), .gestion tr.extra:nth-child(3) th:nth-child(#{$i})  { 
            position: sticky !important;
            left: $pos;
            z-index: 3;
        }

        .gestion tr:not(.red-row):not(.extra) td:nth-child(#{$i}), .gestion tr:nth-child(2) th:nth-child(#{$i})  { 
            //background: white;
            background: $fond-tableau;
        }

        .gestion tr.extra:not(.red-row) td:nth-child(#{$i}), .gestion tr:nth-child(2) th:nth-child(#{$i})  { 
            background: white;
        }

        .gestion tr.red-row:not(.extra) td:nth-child(#{$i})  { 
            background: $redish;
            color: white !important;
        }

        
    }

    tr.red-row td {
        color: white !important;
    }

    .tableau-gestion tr{
        
        background: $fond-tableau ;
        

    }

    // fond jaune pour les 6 premières cellules headers
    @for $i from 1 through 6 {
        .gestion tr:not(.extra):nth-child(2) th:nth-child(#{$i}) {
            background: $yellowish !important;
        }  
    }

    // fond gris pour les colonnes suivantes
    @for $i from 7 through 10 {
        .gestion tr:nth-child(2) th:nth-child(#{$i}) {
            background: $greyish !important;
            color: white !important;
        }  
    }

    // fond bleu pour les suivantes
    @for $i from 11 through 15 {
        .gestion tr:nth-child(2) th:nth-child(#{$i}) {
            background: $blueish !important;
            color: white !important;
        }  
    }

    // fond bleu pour les suivantes
    $yellowpos: 18, 19, 20, 21, 22, 23; 
    @for $i from 1 through length($yellowpos) {
        $pos: nth($yellowpos, $i);
        .gestion tr:nth-child(2) th:nth-child(#{$pos}) {
            background: $yellowish !important;
        }  
    }

    // fond bleu pour les suivantes
    @for $i from 24 through 30 {
        .gestion tr:nth-child(2) th:nth-child(#{$i}) {
            background: $greyish !important;
            color: white !important;
        }  
    }

    // couleurs de fond pour les cellules du extra header
    $extraHeaderColors: $greyish, $blueish, $yellowish, $greyish;
    @for $i from 1 through length($extraHeaderColors) {
        $color: nth($extraHeaderColors, $i);
        $index: $i + 6;
        .gestion tr:first-child:not(.extra) th:nth-child(#{$index}) {
            background: $color !important;
            border-color: white;
            color: white !important;
        }
    }

    .gestion tr:first-child:not(.extra) th:nth-child(9) {
        color: $headercolor !important;
    }

    // extra header est une ligne sticky
    .gestion tr:not(.extra):first-child th {
        position: sticky !important;
        top: 0 !important;
        z-index: 4 !important;
        border-color: white !important;
    }


    // extra header est une ligne sticky
    .gestion tr:not(.extra):nth-child(2) th {
        position: sticky !important;
        top: 29px !important;
        z-index: 4 !important;
    }

    // 6 premières colonnes => cas particulier pour qu'elles restent sticky verticalement et horizontalement
    .gestion tr:not(.extra):nth-child(2) th:nth-child(-n+6) {
        z-index: 5 !important;
        border-color: white !important;
    }

    .pointer {
        cursor: pointer;
    }

    .not-allowed {
        cursor: not-allowed;
    }

    @for $i from 1 through length($colspos) {
        $pos: nth($colspos, $i);
        .gestion tr:first-child th:nth-child(#{$i}) {
            background: white !important;
            z-index: 5 !important;
            position: sticky !important;
            left: $pos;
        }  
    }

    .gestion tr.extra th:nth-child(-n+6) {
        border-bottom-color: white !important;
    }

    .gestion tr.border-white td, td.border-white {
        border-color: white !important;
    }

    .border-bottom-white {
        border-bottom-color: white !important;
    }

    .red-row {
        color: white !important;
        background: $redish !important;
    }

    tr.red-row button {
        color: white !important;
        border-color: white !important;
    }

    .gestion tr.extra td {
        font-weight: bold;
    }

    .gestion tr:not(.extra):nth-child(2) th:last-child {
        background: white !important;
        border-color: white !important;
    }

    .gestion td, .gestion th {
        font-size: 10px !important;
    }

    .bold {
        font-weight: bold;
    }

    .gestion tr:not(.extra) th.bg-white {
        background: white !important;
    }

</style>