const getPercentField = function(value1, value2) {
    return value1 == 0 ? 0 : (value2 * 100) / value1;
};


export const lastPointGestionDirectionCells = [
    "totalVentePG",
    "totalAchatPG",
    "margePG",
    "margePG%",
    "croissance",
    "totalCommande",
    "margeCommande",
    "margeCommande%",
    "controlCommande"
]

export const applyFormatCallbackTo = [
    "totalCommande",
    "margeCommande",
    "totalVentePG",
    "totalAchatPG",
    "margePG",
    "mbCumul",
    "activite",
    "factCumul",
    "depenseCumul",
    "sitMeriteeN",
    "enCoursN",
    "factN",
    "depenseN",
    "sousFact",
    "MBN",
    "engage",

    "margeCommande%",
    "margePG%",
    "mbCumul%",
    "%fact/Avt",
    "MBN%"
];

export const tableauGestionColumns = [
    // BLOC INFOS
    {
        label: 'N°',
        field: 'affaire.num',
        width: "50px",
        tdClass: "pointer",
    },
    {
        label: 'Titre',
        field: 'affaire.titre',
        width: '200px',
        tdClass: "pointer"
    },
    {
        label: 'RESP',
        field: 'userChargeAffaire.username',
        width: '35px'
    },
    {
        label: 'Date Entrée',
        field: 'dateEntree',
        width: "65px",

    },
    {
        label: 'Etat',
        field: 'etat',
        width: '35px'
    },
    {
        label: 'Date Sortie',
        field: 'dateSortie',
        width: "65px",
    },

    // BLOC COMMANDE
    {
        label: 'PV Budget',
        field: 'totalCommande',
        width: '80px',
        type: "number"
    },
    {
        label: 'MB Budget',
        field: 'margeCommande',
        type: "number",
        width: '80px'
    },
    {
        label: 'MB% Budget',
        field: 'margeCommande%',
        valueCallback: ({totalCommande, margeCommande}) => getPercentField(totalCommande, margeCommande),
        type: "number",
        width: '55px',
        tdClass: "bold",
    },
    {
        label: 'Control',
        field: 'controlCommande',
        width: '30px'
    },

    // BLOC POINT GESTION
    {
        label: 'PV pg M',
        field: 'totalVentePG',
        type: "number",
        width: '80px'
    },
    {
        label: 'PR pg M',
        field: 'totalAchatPG',
        type: "number",
        width: '80px'
    },
    {
        label: 'MB pg M',
        field: 'margePG',
        type: "number",
        width: '80px'
    },
    {
        label: 'MB pg M',
        field: 'margePG%',
        valueCallback: (row) => getPercentField(row.totalVentePG, row.margePG),
        type: "number",
        width: '55px',
    },
    {
        label: '',
        field: 'croissance',
        type: 'number'
    },

    // BLOC CUMUL
    {
        label: 'ACTIVITE',
        field: 'activite',
        type: "number",
        width: '80px',
        thClass: "grey-bg",
        tdClass: "light-grey-bg"
    },
    {
        label: 'SOUS FACT',
        field: 'sousFact',
        type: "number",
        width: '80px',
        thClass: "grey-bg",
        tdClass: "light-grey-bg"
    },
    {
        label: 'Fact Cumul',
        field: 'factCumul',
        type: "number",
        width: '80px'
    },
    {
        label: 'DEPENSES Cumul',
        field: 'depenseCumul',
        type: "number",
        width: '80px'
    },
    {
        label: 'MB Cumul',
        field: 'mbCumul',
        type: "number",
        width: '80px'
    },
    {
        label: '% MB Cumul',
        field: 'mbCumul%',
        valueCallback: (row) => getPercentField(row.factCumul, row.mbCumul),
        type: "number",
        width: '55px'
    },
    {
        label: '% Fact/Avt',
        field: '%fact/Avt',
        valueCallback: (row) => getPercentField(row.activite, row.depenseCumul),
        type: "number",
        width: '55px'
    },
    {
        label: 'Engagés',
        field: 'engage',
        type: "number",
        width: '80px'
    },

    // BLOC EXERCICE AU STADE
    {
        label: 'SIT MERITEE N',
        field: 'sitMeriteeN',
        type: "number",
        width: '80px'
    },
    {
        label: 'ENCOURS N',
        field: 'enCoursN',
        type: "number",
        width: '80px'
    },
    {
        label: 'FACT N',
        field: 'factN',
        type: "number",
        width: '80px'
    },
    {
        label: 'DEPENSES N',
        field: 'depenseN',
        type: "number",
        width: '80px'
    },
    {
        label: 'MB N',
        field: 'MBN',
        type: "number",
        width: '80px'
    },
    {
        label: '% MB N',
        field: 'MBN%',
        valueCallback: (row) => getPercentField(row.sitMeriteeN, row.MBN),
        type: "number",
        width: '55px'
    },
];